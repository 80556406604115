<template>
  <div class="orderReport container">
	<p class="h6 text-center text-primary"><b>{{ reportName }}</b></p>    
	<br>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="fromdate">Start Date:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold mr-1" for="todate">End Date:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-4" v-model="todate" name="todate"></datepicker>
				
					<label class="col-form-label font-weight-bold mr-1">GSTN?</label>
					<select class="form-control form-select mr-4" v-model="selGSTN">
						<option selected value="NA">NA</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>

					<CButton color="dark" v-on:click.prevent="serverCall('getGSTR1Data')">Fetch Data</CButton>	
				</div>
			</form> 
			<br>
			<div class="row">
				<label class="font-weight-bold mr-1" for="fromdate">Compare with Talley GSTR1 File:</label>
				<input type="file" id="input" @change="onChangeGST"/>
			</div>

		</CCardBody>
	</CCard>

	<div class="text-center">
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<div>
		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:row-style-class="rowStyleClassFn"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
		>
			<div slot="table-actions">
				<CButton color="dark" class="mr-2" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"
import readXlsxFile from 'read-excel-file'

export default {
	name: 'GSTR1',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			columns: [],
			rows: [],
			dataList: [],
			columnsCompareGSTR1: [
				{
					label: 'Invoice Number',
					field: 'invoiceNbr',
					filterOptions: {'enabled': true},
					sortable: true,
				},
				{
					label: 'Total - Talley',
					field: 'Gross Total_x',
					filterOptions: {'enabled': true},
					sortable: true,
				},
				{
					label: 'Total - System',
					field: 'Gross Total_y',
					filterOptions: {'enabled': true},
					sortable: true,
				},
				{
					label: 'Order Nbr',
					field: 'Order Nbr',
					filterOptions: {'enabled': true},
				},
			],
			orderType: this.$route.params.orderType,

			reportTitle: "GSTR1",
			reportName: "GSTR1",
			reportLongName: "",
			reportData: "",
			fromdate: null,
			todate: null,
			selGSTN: 'NA',

			disabledDates: {
          		to: new Date(new Date().getTime() - (150 * 24 * 60 * 60 * 1000)),
				from: new Date(), // Disable all dates after specific date
			},

			alert: null,
			loading: false,
		
		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		
		this.fromdate = new Date()
		this.todate = new Date()
		this.orderType = this.$route.params['orderType']
		this.reportName = this.$route.params['reportName']

		if (this.reportName=='GSTR1Sales')
			this.reportTitle = this.orderType + " - GSTR1"

	},

	methods: {
        formatNumberFn: function(value) {
          return value.toFixed(2)
        },
		
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'active'
			}
		},

        serverCall(name, data) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getGSTR1Data":
								self.getGSTR1Data(idToken)
								break;

						case "compareGSTR1":
								self.compareGSTR1(idToken, data)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})

		},
		

        // Get GSTR1 Data for the given dates.
        getGSTR1Data(authToken) {
            var self = this
			self.alert = null

            if (!this.fromdate || !this.todate || !this.orderType){
                self.alert = "Either fromdate or todate or orderType not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching GSTR1 Data..."

			// Reset file value	
			document.getElementById('input').value = ""

			var postJson = {
				'orderType': this.orderType,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
				'reportName': this.reportName,
				'selGSTN': this.selGSTN
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetGSTR1Data/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getGSTRData - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


		downloadReport() {
			if (this.reportData==""){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

		onChangeGST(){
			let finalRows = [['Voucher Ref. No.', 'Gross Total']]
			const input = document.getElementById('input')
			readXlsxFile(input.files[0], { sheet: 'B2B' }).then((rows) => {
				console.log("1")
				if (this.selGSTN == 'Yes' || this.selGSTN == 'All'){
					let i = 0
					while (i<rows.length){
						// Add row if InvoiceNbr is not null
						if (rows[i][2] && rows[i][0] != 'Date'){
							finalRows.push( [ rows[i][2], rows[i][4] ] )
						}
						i = i + 1
					}
				}

				readXlsxFile(input.files[0], { sheet: 'B2C' }).then((rows) => {

					if (this.selGSTN == 'No' || this.selGSTN == 'All'){
						let i = 0
						while (i<rows.length){
							// Add row if InvoiceNbr is not null
							if (rows[i][4] && rows[i][0] != 'Date'){
								finalRows.push( [ rows[i][2], rows[i][4] ] )
								//finalRows.push( [ rows[i][4], rows[i][6] ] )
							}
							i = i + 1
						}
					}

					this.serverCall('compareGSTR1', finalRows)
				})


			})
		},

        // Compare Talley GSTR with System data
        compareGSTR1(authToken, data) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Comparing Talley GSTR with System data..."

			var postJson = {
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
				'gstr1Data': data,
				'selGSTN': this.selGSTN
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/CompareGSTR1/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("compareGSTR1 - error:" + response.data.err)
					}
					else {
						self.columns = self.columnsCompareGSTR1
						self.rows = response.data.dataList
						self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

